import { useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
// in memory fallback used when `localStorage` throws an error
export const inMemoryData = new Map();
export default function useLocalStorageState(key, options) {
    const serializer = options?.serializer;
    const [defaultValue] = useState(options?.defaultValue);
    return useLocalStorage(key, defaultValue, options?.storageSync, serializer?.parse, serializer?.stringify);
}
function useLocalStorage(key, defaultValue, storageSync = true, parse = parseJSON, stringify = JSON.stringify) {
    // we keep the `parsed` value in a ref because `useSyncExternalStore` requires a cached version
    const storageItem = useRef({
        string: null,
        parsed: undefined,
    });
    const value = useSyncExternalStore(
    // useSyncExternalStore.subscribe
    useCallback((onStoreChange) => {
        const onChange = (localKey) => {
            if (key === localKey) {
                onStoreChange();
            }
        };
        callbacks.add(onChange);
        return () => {
            callbacks.delete(onChange);
        };
    }, [key]), 
    // useSyncExternalStore.getSnapshot
    () => {
        const string = goodTry(() => localStorage.getItem(key)) ?? null;
        if (inMemoryData.has(key)) {
            storageItem.current.parsed = inMemoryData.get(key);
        }
        else if (string !== storageItem.current.string) {
            let parsed;
            try {
                parsed = string === null ? defaultValue : parse(string);
            }
            catch {
                parsed = defaultValue;
            }
            storageItem.current.parsed = parsed;
        }
        storageItem.current.string = string;
        // store default value in localStorage:
        // - initial issue: https://github.com/astoilkov/use-local-storage-state/issues/26
        //   issues that were caused by incorrect initial and secondary implementations:
        //   - https://github.com/astoilkov/use-local-storage-state/issues/30
        //   - https://github.com/astoilkov/use-local-storage-state/issues/33
        if (defaultValue !== undefined && string === null) {
            // reasons for `localStorage` to throw an error:
            // - maximum quota is exceeded
            // - under Mobile Safari (since iOS 5) when the user enters private mode
            //   `localStorage.setItem()` will throw
            // - trying to access localStorage object when cookies are disabled in Safari throws
            //   "SecurityError: The operation is insecure."
            // eslint-disable-next-line no-console
            goodTry(() => {
                const string = stringify(defaultValue);
                localStorage.setItem(key, string);
                storageItem.current = { string, parsed: defaultValue };
            });
        }
        return storageItem.current.parsed;
    }, 
    // useSyncExternalStore.getServerSnapshot
    () => defaultValue);
    const setState = useCallback((newValue) => {
        const value = newValue instanceof Function ? newValue(storageItem.current.parsed) : newValue;
        // reasons for `localStorage` to throw an error:
        // - maximum quota is exceeded
        // - under Mobile Safari (since iOS 5) when the user enters private mode
        //   `localStorage.setItem()` will throw
        // - trying to access `localStorage` object when cookies are disabled in Safari throws
        //   "SecurityError: The operation is insecure."
        try {
            localStorage.setItem(key, stringify(value));
            inMemoryData.delete(key);
        }
        catch {
            inMemoryData.set(key, value);
        }
        triggerCallbacks(key);
    }, [key, stringify]);
    const removeItem = useCallback(() => {
        goodTry(() => localStorage.removeItem(key));
        inMemoryData.delete(key);
        triggerCallbacks(key);
    }, [key]);
    // - syncs change across tabs, windows, iframes
    // - the `storage` event is called only in all tabs, windows, iframe's except the one that
    //   triggered the change
    useEffect(() => {
        if (!storageSync) {
            return undefined;
        }
        const onStorage = (e) => {
            if (e.key === key && e.storageArea === goodTry(() => localStorage)) {
                triggerCallbacks(key);
            }
        };
        window.addEventListener('storage', onStorage);
        return () => window.removeEventListener('storage', onStorage);
    }, [key, storageSync]);
    return useMemo(() => [
        value,
        setState,
        {
            isPersistent: value === defaultValue || !inMemoryData.has(key),
            removeItem,
        },
    ], [key, setState, value, defaultValue, removeItem]);
}
// notifies all instances using the same `key` to update
const callbacks = new Set();
function triggerCallbacks(key) {
    for (const callback of [...callbacks]) {
        callback(key);
    }
}
// a wrapper for `JSON.parse()` that supports "undefined" value. otherwise,
// `JSON.parse(JSON.stringify(undefined))` returns the string "undefined" not the value `undefined`
function parseJSON(value) {
    return value === 'undefined' ? undefined : JSON.parse(value);
}
function goodTry(tryFn) {
    try {
        return tryFn();
    }
    catch { }
}
